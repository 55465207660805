import dynamic from "next/dynamic";
import { renderLineBreaks } from "../../../utils";
import { NextImage } from "../index";

import { MiniCardItemWrapper, TitleWrapper, TagWrapper } from "./styles";

const Link = dynamic(() => import("../link/link"));

const Title = ({ children, tag, icon, featured, listOnMobile }) => {
  return (
    <TitleWrapper
      featured={featured}
      hasTag={!!tag}
      hasIcon={!!icon}
      listOnMobile={listOnMobile}
    >
      {featured ? (
        <h3>{renderLineBreaks(children)}</h3>
      ) : (
        <p>{renderLineBreaks(children)}</p>
      )}
      {tag && !featured && <Tag listOnMobile={listOnMobile}> {tag}</Tag>}
    </TitleWrapper>
  );
};

const Tag = ({ children, listOnMobile }) => {
  return <TagWrapper listOnMobile={listOnMobile}>{children}</TagWrapper>;
};

const MiniCard = ({
  title,
  icon,
  tag,
  featured,
  hideOnMobile,
  fullImage,
  listOnMobile,
  isVisibleOnMobile,
  link,
}) => {
  const onlyIcon = !title && !tag && icon;
  const hasLink = !!link;
  const cardProps = {
    title,
    icon,
    featured,
    hideOnMobile: isVisibleOnMobile ? false : hideOnMobile,
    fullImage,
    listOnMobile,
    onlyIcon,
  };

  return (
    <MiniCardItemWrapper {...cardProps} hasLink={hasLink}>
      {icon && !featured && <NextImage media={icon} alt={title} />}
      {(title || tag) && (
        <Title
          tag={tag}
          featured={featured}
          icon={icon}
          listOnMobile={listOnMobile}
        >
          {title}
        </Title>
      )}
    </MiniCardItemWrapper>
  );
};

export const MiniCardItem = (props) => {
  const { link } = props;
  const hasLink = !!link;

  return hasLink ? (
    <Link {...link} noUnderline>
      <MiniCard {...props} />
    </Link>
  ) : (
    <MiniCard {...props} />
  );
};

export default MiniCardItem;
