import styled, { css } from "styled-components";

export const MiniCardItemWrapper = styled.li`
  display: flex;
  height: 160px;
  flex-direction: column;
  justify-content: center;
  padding: 0px var(--spacingM);
  border-radius: var(--radiusL);
  background-color: var(--gray100);
  align-items: center;
  padding: var(--spacingXs) var(--spacingM);
  gap: var(--spacingS);

  > img {
    width: 100%;
    height: 40px;
  }

  ${({ onlyIcon }) =>
    onlyIcon &&
    css`
      > img {
        height: auto;
        max-width: 120px;
      }
    `}

  ${({ fullImage }) =>
    fullImage &&
    css`
      padding: 0px;
      overflow: hidden;
      > img {
        height: 100%;
        width: 100%;
        max-height: 100%;
        max-width: 100%;
        object-fit: cover;
      }
    `}

  ${({ featured }) =>
    featured &&
    css`
      grid-column-end: span 2;
      background-color: var(--secondary100);
    `}

  ${({ hasLink }) =>
    hasLink &&
    css`
      transition: 0.5s;
      :hover {
        background-color: var(--gray200);
      }
    `}

  @media screen and (max-width: 400px) {
    ${({ fullImage }) =>
      !fullImage &&
      css`
        padding: var(--spacingXs) var(--spacingS);
      `}
  }

  @media screen and (max-width: 1128px) {
    ${({ listOnMobile, featured, title, icon }) =>
      listOnMobile &&
      css`
        width: 100%;
        min-height: var(--spacing2Xl);
        height: auto;
        display: ${title && icon ? "flex" : "none"};
        flex-direction: row;
        justify-content: flex-start;
        background-color: white;
        border-radius: 0;
        > img {
          object-fit: cover;
          width: 24px;
          height: 24px;
        }

        &:not(:last-of-type) {
          border-bottom: 1px solid var(--gray200);
        }

        ${featured &&
        css`
          display: flex;
          justify-content: center;
          height: 160px;
          background-color: var(--secondary100);
          border-radius: var(--radiusXs);
          border-bottom: 0 !important;
        `}
      `}
  }

  @media screen and (max-width: 1128px) {
    ${({ featured }) =>
      featured &&
      css`
        order: -1;
      `}
    ${({ hideOnMobile }) =>
      hideOnMobile &&
      css`
        display: none;
      `}
  }
`;

export const TitleWrapper = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-align: center;
  max-width: 100%;
  overflow: hidden;

  > p {
    margin: 0px;
    font-size: var(--textS);
    line-height: var(--lineHeightS);
    font-weight: normal;
    margin-bottom: var(--spacingXxs);
  }

  ${({ hasIcon }) =>
    !hasIcon &&
    css`
      -webkit-line-clamp: 4;
    `}


  ${({ featured }) =>
    featured &&
    css`
      display: flex;
      align-items: center;
      height: auto;
      overflow: hidden;

      > h3 {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        font-size: var(--textL);
        line-height: var(--lineHeightL);
        font-weight: normal;
        margin-bottom: 0px;
        color: var(--primary800);
      }
    `}

 

  @media screen and (max-width: 1128px) {
    ${({ listOnMobile, featured }) =>
      listOnMobile &&
      css`
        height: auto;
        > p {
          text-align: ${featured ? "center" : "left"};
          margin: 0;
        }
      `}
  }
`;

export const TagWrapper = styled.span`
  display: block;
  height: 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  align-items: center;
  text-transform: uppercase;
  background-color: var(--primary800);
  color: white;
  border-radius: var(--radiusS);
  padding: 1px var(--spacingXs) 0;
  font-size: 12px;
  line-height: 14px;


`;

export default MiniCardItemWrapper;
